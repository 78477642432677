import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      url: "",
      address: "",
      count: 0,
      app_root: api.base(),
      list: [],
      list1: [],
      list2: [],
      loading: false,
      finished: false,
      loading1: false,
      finished1: false,
      loading2: false,
      finished2: false,
      page: 1,
      page1: 1,
      page2: 1,
      head_bg: ""
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    _this.get_url();
    this.head_bg = local.readInfo('head_bg');
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    //切换语言
    onLoad() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading = true;
      api.all('/api/user/user_team_list', {
        page: this.page,
        type: 1
      }, (err, data) => {
        this.loading = false;
        if (!err && data.code === 1) {
          this.page = this.page + 1;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list.push(data.data.list[i]);
          }
          if (this.list.length >= data.data.total) {
            this.finished = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onLoad1() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading1 = true;
      api.all('/api/user/user_team_list', {
        page: this.page1,
        type: 2
      }, (err, data) => {
        this.loading1 = false;
        if (!err && data.code === 1) {
          this.page1 = this.page1 + 1;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list1.push(data.data.list[i]);
          }
          if (this.list1.length >= data.data.total) {
            this.finished1 = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onLoad2() {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      this.loading2 = true;
      api.all('/api/user/user_team_list', {
        page: this.page2,
        type: 3
      }, (err, data) => {
        this.loading2 = false;
        if (!err && data.code === 1) {
          this.page2 = this.page2 + 1;
          for (let i = 0; i < data.data.list.length; i++) {
            this.list2.push(data.data.list[i]);
          }
          if (this.list2.length >= data.data.total) {
            this.finished2 = true;
          }
        } else {
          console.log(data);
        }
      });
    },
    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    get_imdex_data: function () {
      api.all('/api/user/user_invite', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.count = data.data.count;
          this.address = "http://" + window.location.hostname + "/#/reg?code=" + this.user.code;
        } else {
          console.log(data);
        }
      });
    },
    get_url: function () {
      api.all('/api/user/code_url', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.url = data.data.url;
        } else {
          console.log(data);
        }
      });
    },
    copyToClipboard11() {
      if (this.user.code == null || this.user.code == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.user.code).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    copyToClipboard() {
      if (this.address == null || this.address == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.address).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    goto: function (name) {
      let _this = this;
      _this.$router.push({
        name: name,
        query: {
          id: 1
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    }
  }
};